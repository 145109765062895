<template>
  <!-- 个人担保新增弹窗模块 -->
  <div class="addPersonalMain">
    <!-- 选择个人担保弹窗 -->
    <base-dialog
      :visible="visible"
      width="1100px"
      :showFooter="false"
      @close="emitVisible"
      class="addPersonal"
      title="选择"
    >
      <div class="content">
        <p>
          若无人员信息，可点击<span class="link" @click="addPersonal">此处</span
          >进行新增！
        </p>
        <!-- 查询条件 -->
        <base-form
          :componentList="formConfig"
          :formAttrs="{
            model: queryParas,
            labelWidth: '90px',
          }"
          class="formStyle"
          @handleFilter="handleFilter"
          @clearParams="clearParams"
          :btnReset="false"
          ref="formData"
        >
        </base-form>
        <!-- 表格数据 -->
        <base-table
          :showPage="false"
          :columns="columns"
          :tableAttrs="{
            data: tableData,
            stripe: true,
          }"
          @select="select"
          :queryParas="queryParas"
          @selection-change="change"
          :dataSource.sync="tableData"

          ref="tableData"
          :isCaculateHeight="false"
          v-if="dictType==='1'"
        >
          <template slot="index" slot-scope="scope">{{
            scope.$index + 1
          }}</template>
        </base-table>
        <!-- 财富资金版本 -->
        <!-- 中小担版本 -->
        <base-table
          :columns="newColumns"
          :showPage="false"
          :tableAttrs="{
            data: tableData,
            stripe: true,
          }"
          @select="select"
          @selection-change="change"
          :dataSource.sync="tableData"
          @pageIndexChange="pageIndexChange"
          @pageSizeChange="pageSizeChange"
          ref="tableData"
          :isCaculateHeight="false"
          @expand-change="expandChange"
          v-if="dictType==='3'||dictType==='4'"
        >
          <template slot="index" slot-scope="scope">{{
            scope.$index + 1
          }}</template>
          <template slot="code" slot-scope="scope">
            <base-table
              :columns="relationshipTableConfig"
              :showPage="false"
              :tableAttrs="{
                data: scope.row.personContactInfos,
                stripe: true,
              }"
              @select="selectRelationship"
              @selection-change="changeRelationship"
              :dataSource.sync="scope.row.personContactInfos"
              :ref="'relationshipTableData'+scope.$index"
              :webPage="false"
              :isCaculateHeight="false"

            >
            <template slot="implementSituation" slot-scope="newscope">
        <el-checkbox
          v-model="newscope.row.implementSituation"
          :true-label="1"
          :false-label="0"
          @change="checkboxchange(newscope.row,newscope.row.implementSituation, newscope.$index,scope.$index)"
        ></el-checkbox>
      </template>
            </base-table>
          </template>
        </base-table>
      </div>
      <template slot="footer">
        <base-button label="提 交" @click="confirm"></base-button>
        <base-button
          label="关 闭"
          type="default"
          @click="emitVisible"
        ></base-button>
      </template>
    </base-dialog>
    <!-- 新增用户弹窗 -->
    <base-dialog
      :visible="addVisible"
      width="1100px"
      :showFooter="false"
      @close="addVisible = false"
      class="addPersonal"
      title="新增"
    >
      <div>
        <div style="text-align: right; margin-bottom: 10px">
          <base-button
            label="新增"
            @click="
              addData.push({ enterpriseId: gysId, personContactInfos: [] })
            "
          ></base-button>
        </div>
        <!-- 表格录入数据 -->
        <base-table
          :columns="addcolumns"
          :showPage="false"
          :tableAttrs="{
            data: addData,
            stripe: true,
          }"
          :isCaculateHeight="false"
          @expand-change="expandChangeAdd"
        >
         <template slot="address" slot-scope="scope">
                <el-input
                  maxlength="50"
                  placeholder="请输入"
                  v-model="scope.row.address"

                ></el-input>
              </template>
          <template slot="code" slot-scope="scope">
            <base-table
              :columns="addRelationTableConfig"
              :showPage="false"
              :tableAttrs="{
                data: scope.row.personContactInfos,
                stripe: true,
              }"
              :isCaculateHeight="false"
            >
              <template slot="personnalName" slot-scope="scope">
                <el-input
                  maxlength="20"
                  placeholder="请输入"
                  @input="
                    scope.row.personnalName = scope.row.personnalName.replace(
                      /[^\a-\z\A-\Z\u4E00-\u9FA5\.\,\?\<\>\。\，\-\_\——\=\;\@\！\!\+\$]/g,
                      ''
                    )
                  "
                  v-model="scope.row.personnalName"
                ></el-input>
              </template>
              <template slot="idCard" slot-scope="scope">
                <el-input
                  maxlength="18"
                  placeholder="请输入"
                  @input="
                    scope.row.idCard = scope.row.idCard.replace(/[^\w]/g, '')
                  "
                  v-model="scope.row.idCard"
                  @blur="idCardPhone(scope.row.idCard)"
                ></el-input>
              </template>
              <template slot="mobilePhoneNo" slot-scope="scope">
                <el-input
                  maxlength="11"
                  placeholder="请输入"
                  v-model="scope.row.mobilePhoneNo"
                  @input="
                    scope.row.mobilePhoneNo = scope.row.mobilePhoneNo.replace(
                      /[^\d]/g,
                      ''
                    )
                  "
                  @blur="blurPhone(scope.row.mobilePhoneNo)"
                ></el-input>
              </template>
              <template slot="address" slot-scope="scope">
                <el-input
                  maxlength="50"
                  placeholder="请输入"
                  v-model="scope.row.address"

                ></el-input>
              </template>
              <template slot="contactType" slot-scope="scope">
                <base-select
                  v-model="scope.row.contactType"
                  placeholder="请选择"
                  :options="getDictLists('PERSON_CONTACT_TYPE')"
                  :selectedField="['dictId', 'dictName']"
                ></base-select>
              </template>
              <template slot="actionHeader">
                <div class="addRelation">
                  <div>操作</div>
                  <div @click="addRelation(scope.row, scope.$index)">
                    <i class="el-icon-plus"></i>
                  </div>
                </div>
              </template>
              <template slot="action" slot-scope="newScope">
                <IconButton
                  content="删除"
                  @click="removeRelation(scope.$index,newScope.$index)"
                  icon="iconfont iconshanchu1"
                ></IconButton>
              </template>
            </base-table>
          </template>
          <template slot="personnerName" slot-scope="scope">
            <el-input
              maxlength="20"
              placeholder="请输入"
              @input="
                scope.row.personnerName = scope.row.personnerName.replace(
                  /[^\a-\z\A-\Z\u4E00-\u9FA5\.\,\?\<\>\。\，\-\_\——\=\;\@\！\!\+\$]/g,
                  ''
                )
              "
              v-model="scope.row.personnerName"
            ></el-input>
          </template>
          <template slot="idCard" slot-scope="scope">
            <el-input
              maxlength="18"
              placeholder="请输入"
              @input="scope.row.idCard = scope.row.idCard.replace(/[^\w]/g, '')"
              v-model="scope.row.idCard"
              @blur="idCardPhone(scope.row.idCard)"
            ></el-input>
          </template>
          <template slot="mobilePhoneNo" slot-scope="scope">
            <el-input
              maxlength="11"
              placeholder="请输入"
              v-model="scope.row.mobilePhoneNo"
              @input="
                scope.row.mobilePhoneNo = scope.row.mobilePhoneNo.replace(
                  /[^\d]/g,
                  ''
                )
              "
              @blur="blurPhone(scope.row.mobilePhoneNo)"
            ></el-input>
          </template>
            <!-- @input="
                scope.row.dutiesName = scope.row.dutiesName.replace(
                  /[^\u4E00-\u9FA5]/g,
                  ''
                )
              " -->
          <template slot="dutiesName" slot-scope="scope">
            <el-input
              maxlength="20"
              placeholder="请输入"

              v-model="scope.row.dutiesName"
            ></el-input>
          </template>
          <template slot="personTypeCode" slot-scope="scope">
            <base-select
              v-model="scope.row.personTypeCode"
              placeholder="请选择"
              multiple
              @change="personTypeName(scope.row.personTypeCode, scope.row)"
              :options="getDictLists('PERSONNEL_TYPE')"
              :selectedField="['dictId', 'dictName']"
            ></base-select>
          </template>
          <template slot="action" slot-scope="scope">
            <!-- <IconButton
              content="家庭成员"
              @click="add()"
              icon="iconfont iconchengyuan"
            ></IconButton> -->
            <IconButton
              content="删除"
              @click="remove(scope.$index)"
              icon="iconfont iconshanchu1"
            ></IconButton>
          </template>
        </base-table>
      </div>
      <template slot="footer">
        <base-button label="提交" @click="batchSave"></base-button>
        <!-- <base-button label="提交并确认选择" @click="addBatchSave"></base-button> -->
        <base-button
          label="关 闭"
          type="default"
          @click="addVisible = false"
        ></base-button>
      </template>
    </base-dialog>
  </div>
</template>

<script>
import baseDialog from '@/components/common/base-dialog/base-dialog.vue'
import BaseButton from '@/components/common/button/base-button/base-button.vue'
import BaseForm from '@/components/common/base-form//base-form.vue'
import {
  formConfig,
  tableConfig,
  addTableConfig,
  relationshipTableConfig,
  addRelationTableConfig,
  newColumns
} from '../utils/add-config'
import BaseTable from '@/components/common/table/base-table/base-table.vue'
import { contactApi, relationApi } from '@/api/contactApi'
import BaseSelect from '@/components/common/base-select/base-select.vue'
import { getDictLists } from '@/filters/fromDict'
import { idCardReg, phoneReg } from '@/config/reg-config'
import IconButton from '@/components/common/button/icon-button/icon-button.vue'
// import * as validate from '@/utils/validate'
export default {
  name: 'addPersonal',
  components: {
    baseDialog,
    BaseButton,
    BaseForm,
    BaseTable,
    BaseSelect,
    IconButton
  },
  props: {
    dictType: String, // 产品类型
    visible: Boolean,
    keyId: String,
    personalData: Array, // 外层传入选中的数据
    gysId: String,
    schemeInfoList: Array
  },
  data () {
    return {
      expandedRows: {},
      total: 0,
      selectData: [], // 已选中的数据
      queryParas: {
        pageSize: 10,
        pageIndex: 1
      },
      loadCount: 0,
      tableData: [],
      addVisible: false,
      changeData: [],
      state: true,
      addData: [{}], // 新增人员数据
      supervise: [
        {
          label: '开户行：',
          prop: 'bankName',
          attrs: {
            placeholder: '请输入',
            maxlength: 50
          },
          rules: [{ required: true, message: '请输入开户行', trigger: 'blur' }]
        },
        {
          label: '账号：',
          prop: 'accountNo',
          attrs: {
            placeholder: '请输入',
            maxlength: 50
          },
          rules: [{ required: true, message: '请输入账号', trigger: 'blur' }]
        }
      ],
      rules: [],
      flag: true // 控制每次打开弹窗只执行一次带入数据的回显
    }
  },
  watch: {
    visible (val) {
      if (val) {
        this.clearParams()
        this.tableData = []
        this.flag = true
        this.changeData = []
        this.$set(this.queryParas, 'enterpriseId', this.gysId)
        this.$nextTick(() => {
          this.handleFilter()
        })
      }
    },
    tableData: {
      handler (val) {

      },
      deep: true
    }
  },
  computed: {
    addRelationTableConfig () {
      return addRelationTableConfig()
    },
    relationApi () {
      return relationApi
    },
    relationshipTableConfig () {
      return relationshipTableConfig(this)
    },
    formConfig () {
      return formConfig(this)
    },
    columns () {
      return tableConfig(this)
    },
    newColumns () {
      return newColumns(this)
    },
    addcolumns () {
      return addTableConfig(this)
    },
    api () {
      return contactApi
    },
    getDictLists () {
      return getDictLists
    }
  },
  methods: {
    updateAllSelected () {

    },
    // 家庭关系子表格选中
    checkboxchange (data, implementSituation, index, key) {
      this.changeData.forEach((item, index) => {
        if (item.keyId === data.personId || item.keyId === data.personnalid) {
          if (data.implementSituation === 1) {
            this.changeData[index].chosePersonContactInfos.push(data)
          } else if (data.implementSituation === 0) {
            this.changeData[index].chosePersonContactInfos.splice(0, 1)
          }
          console.log(this.changeData[index])
        }
      })
    },
    // 删除子表格数据
    removeRelation (index, i) {
      this.addData[index].personContactInfos.splice(i, 1)
    },
    // 添加家庭成员
    expandChangeAdd (row, expandedRows) {},
    addRelation (row, i) {
      this.addData[i].personContactInfos.push({ enterpriseId: this.gysId })
    },
    // 回显子数据
    choseRelationship () {
      this.$nextTick(() => {
        // 选中的数据 this.personalData
        // 获取的数据 this.tableData
        this.tableData.forEach((item, index) => {
          this.personalData.forEach((ele, key) => {
            if (item.keyId === ele.personnalid && ele.personContactInfos && ele.personContactInfos.length > 0) {
              item.personContactInfos.forEach((e, i) => {
                ele.personContactInfos.forEach((v, j) => {
                  if (e.idCard === v.idCard) {
                    this.$set(this.tableData[index].personContactInfos[i], 'implementSituation', 1)
                  }
                })
              })
            }
          })
        })
      })
    },
    // 父级展开
    expandChange (row, expandedRows) {
      if (expandedRows.length > 0) {
        // 展开
        this.expandedRows = row
        this.tableData.forEach((item, index) => {
          if (item === this.expandedRows) {
            this.$refs.tableData.toggleRowSelection(
              this.tableData[index],
              true
            )
          }
        })
        const params = {
          userId: row.keyId
        }
        // 查询字节点数据
        this.relationApi.getPersonContactList(params).then((res) => {
          // personContactInfos
          this.tableData.forEach((item, index) => {
            if (item.keyId === row.keyId) {
              this.$set(this.tableData[index], 'personContactInfos', res.data)
            }
            this.$nextTick(() => {
              this.$nextTick(() => {
                this.$refs.tableData.total = this.total
                this.choseRelationship()
              })
            })
          })
        })
      }
    },
    // 父级 改变选项
    select (selection, row) {
      this.changeData.forEach((item, index) => {
        if (item.idCard === row.idCard) {
          if (row.personContactInfos[0] && row.personContactInfos[0].implementSituation) {
            row.personContactInfos[0].implementSituation = 0
          }
          this.changeData.splice(index, 1)
        }
      })
    },
    // 子级 改变关系选项
    selectRelationship (selection, row) {
      if (this.changeData.indexOf(row) !== -1) {
        this.changeData.splice(this.changeData.indexOf(row), 1)
      }
    },
    emitVisible () {
      this.$emit('emitVisible', false)
    },
    handleFilter () {
      this.queryParas.pageIndex = 1
      this.getlist()
    },
    clearParams () {
      this.queryParas = {
        pageSize: 10,
        pageIndex: 1,
        enterpriseId: this.gysId
      }
    },
    // 获取表格数据
    getlist () {
      this.expandedRows = {}
      this.queryParas.pageSize = 999
      const params = { ...this.queryParas, enterpriseId: this.getGysId() }
      this.api.getPage(params).then((res) => {
        if (res.success) {
          this.tableData = res.data.records
          this.tableData.forEach((item, index) => {
            // 每条数据增加personContactInfos展开项数据
            this.$set(this.tableData[index], 'personContactInfos', [])
          })
          if (this.tableData.length > 0) {
            const that = this
            this.$nextTick(() => {
              if (!this.expandedRows) {
                that.$refs.tableData.clearSelection()
              }
              // 回显选中的选项
              for (let i = 0; i < that.tableData.length; i++) {
                for (let j = 0; j < that.personalData.length; j++) {
                  if (that.tableData[i].personnerName === that.personalData[j].personnalName && that.tableData[i].idCard === that.personalData[j].idCard) {
                    that.$refs.tableData.toggleRowSelection(
                      that.tableData[i],
                      true
                    )
                  }
                }
              }

              // 显示选中的数据
              for (let i = 0; i < that.tableData.length; i++) {
                for (let j = 0; j < that.changeData.length; j++) {
                  if (that.tableData[i].enterpriseName === that.changeData[j].enterpriseName && that.tableData[i].keyId === that.changeData[j].keyId) {
                    that.$refs.tableData.toggleRowSelection(
                      that.tableData[i],
                      true
                    )
                  }
                }
              }
            })
          }
          // 外层传入数据对比，是否选中，选中增加chosePersonContactInfos数组设置选中状态
          for (let i = 0; i < this.tableData.length; i++) {
            for (let j = 0; j < this.personalData.length; j++) {
              if (this.tableData[i].personnerName === this.personalData[j].personnalName && this.tableData[i].idCard === this.personalData[j].idCard) {
                this.$set(this.tableData[i], 'chosePersonContactInfos', this.personalData[i]?.chosePersonContactInfos ? this.personalData[i]?.chosePersonContactInfos : [])
              }
            }
          }
          this.$nextTick(() => {
            this.$nextTick(() => {
              this.total = res.data.total
              this.$refs.tableData.total = res.data.total
            })
          })
        }
      })
    },
    getGysId () {
      const gysId = []
      this.schemeInfoList.forEach((ele) => {
        if (ele.mainBorrowerId) {
          gysId.push(ele.mainBorrowerId, this.gysId)
        }
      })
      if (gysId.length === 0) {
        gysId.push(this.gysId)
      }
      return gysId.join(',')
    },
    pageIndexChange (item) {
      this.queryParas.pageIndex = item
      this.getlist()
    },
    pageSizeChange (item) {
      this.queryParas.pageSize = item
      this.queryParas.pageIndex = 1
      this.getlist()
    },
    // 选择数据
    change (data) {
      // this.changeData = []
      if (data.length === 0) {
        const newArr = []
        this.changeData.forEach((item, index) => {
          if (this.tableData.indexOf(item) !== -1) {
            newArr.push(item)
          }
        })
        if (newArr.length > 0) {
          newArr.forEach((item) => {
            if (this.changeData.indexOf(item) !== -1) {
              this.changeData.splice(this.changeData.indexOf(item), 1)
            }
          })
        }
      }
      data.forEach((ele) => {
        let state = true
        if (!ele.chosePersonContactInfos) {
          ele.chosePersonContactInfos = []
        }

        this.changeData.forEach((eles) => {
          if (eles.keyId === ele.keyId) {
            state = false
          }
        })
        if (state) {
          this.changeData.push(ele)
        }
        if (this.changeData.length === 0) {
          this.changeData.push(ele)
        }
        // const newArr = []
        this.changeData.forEach((item) => {
          this.personalData.forEach((ele) => {
            if (item.idCard === ele.idCard) {
              if (ele.personContactInfos && ele.personContactInfos.length > 0) {
                item.chosePersonContactInfos = ele.personContactInfos
                item.chosePersonContactInfos[0].implementSituation = 1
              }
            }
          })
        })
        // this.changeData = newArr
        console.log(this.changeData, '初始选中')
      })
    },
    // 子级展开-选择关系
    changeRelationship (data) {
      // this.changeData = JSON.parse(JSON.stringify(this.personalData))
      this.changeData.forEach((item, index) => {
        data.forEach((elem) => {
          if (item.keyId === elem.personId) {
            elem.personnalid = elem.personId
            elem.guaranteeKeyId = elem.keyId
            if (this.changeData[index].chosePersonContactInfos.indexOf(elem) === -1) {
              this.changeData[index].chosePersonContactInfos.push(elem)
            }
          }
        })
      })
    },
    // 提交
    confirm () {
      const newArr = []
      this.changeData.map(item => {
        if (item.personnalid) {
          newArr.push(item)
        }
      })

      this.$emit('addPersonal', this.changeData)
      this.emitVisible()
    },
    addPersonal () {
      this.addData = []
      // this.$emit('emitVisible', false)
      this.addVisible = true
    },
    remove (index) {
      this.addData.splice(index, 1)
    },
    // 身份证校验
    idCardPhone (data) {
      if (!idCardReg.test(data)) {
        this.state = false
        return this.warning('请输入正确的身份证')
      } else {
        this.state = true
      }
    },
    // 手机号校验
    blurPhone (data) {
      if (!phoneReg.test(data)) {
        this.state = false
        return this.warning('请输入正确的手机号')
      } else {
        this.state = true
      }
    },
    // 选择人员类型
    personTypeName (type, data) {
      if (type) {
        const personTypeName = []
        type.forEach((item) => {
          getDictLists('PERSONNEL_TYPE').forEach((ele) => {
            if (ele.dictId === item) {
              personTypeName.push(ele.dictName)
            }
          })
        })
        data.personTypeName = personTypeName.join(',')
      }
    },
    // 批量保存企业人员
    batchSave (type) {
      this.state = true
      let status = true
      if (this.addData.length === 0) {
        return this.warning('请添加数据')
      } else {
        this.addData.forEach((ele) => {
          if (
            !ele.personnerName ||
            !ele.idCard ||
            !ele.mobilePhoneNo ||
            !ele.dutiesName ||
            !ele.personTypeCode || ele.personTypeCode.length === 0
          ) {
            status = false
          }
          if (ele.personContactInfos && ele.personContactInfos.length > 0) {
            ele.personContactInfos.forEach((item) => {
              if (
                !item.personnalName ||
                !item.idCard ||
                !item.mobilePhoneNo ||
                !item.contactType
              ) {
                status = false
              }
            })
          }
        })
      }
      if (!status) return this.warning('请完善数据')
      let pass = true
      try {
        this.addData.forEach((item) => {
          if (item.personContactInfos && item.personContactInfos.length > 0) {
            const newArr = item.personContactInfos.map((elem) => {
              if (elem.contactType === '0') {
                return elem
              }
            })
            if (newArr && newArr.length > 1) {
              throw new Error('配偶重复')
            }
          }
        })
      } catch (err) {
        pass = false
      }
      if (!pass) {
        this.warning('配偶关系只能添加一位')
        return false
      }
      let idCardArrPass = true
      let mobilePhonePass = true
      const idCardArr = []
      const mobilePhoneArr = []
      this.addData.forEach((item) => {
        if (idCardArr.indexOf(item.idCard) < 0) {
          idCardArr.push(item.idCard)
        } else {
          idCardArrPass = false
        }
        if (mobilePhoneArr.indexOf(item.mobilePhoneNo) < 0) {
          mobilePhoneArr.push(item.mobilePhoneNo)
        } else {
          mobilePhonePass = false
        }
        if (item.personContactInfos && item.personContactInfos.length > 0) {
          item.personContactInfos.forEach((elem) => {
            if (idCardArr.indexOf(elem.idCard) < 0) {
              idCardArr.push(elem.idCard)
            } else {
              idCardArrPass = false
            }
            if (mobilePhoneArr.indexOf(elem.mobilePhoneNo) < 0) {
              mobilePhoneArr.push(elem.mobilePhoneNo)
            } else {
              mobilePhonePass = false
            }
          })
        }
      })
      if (!idCardArrPass) {
        this.warning('身份证信息重复')
        return
      }
      if (!mobilePhonePass) {
        this.warning('电话号码信息重复')
        return
      }
      for (let i = 0; i < this.addData.length; i++) {
        if (this.state) {
          const data = this.addData[i]
          this.idCardPhone(data.idCard)
          if (this.state) {
            this.blurPhone(data.mobilePhoneNo)
          }
        }
      }
      if (!this.state) return
      const params = this.addData
      params.forEach((item) => {
        item.personType = item.personTypeCode.join(',')
      })
      this.api.batchSave(params).then((res) => {
        if (res.success) {
          if (type === 'add') {
            this.$emit('addPersonal', this.addData)
            this.emitVisible()
          } else {
            this.handleFilter()
          }
          this.success('提交成功')
          this.addVisible = false
        }
      })
    },
    addBatchSave () {
      this.batchSave('add')
    }
  }
}
</script>

<style lang='scss' scoped>
.addRelation {
  display: flex;
  align-items: center;
  > div:nth-child(1) {
    flex: 1;
    text-align: center;
  }
  > div:nth-child(2) {
    cursor: pointer;
    background: #2862E7;
    width: 20px;
    height: 20px;
    text-align: center;
    line-height: 20px;
    color: #fff;
  }
}
.addPersonal /deep/.el-dialog {
  height: 78vh !important;
}
.content {
  padding: 20px;
  .link {
    color: rgb(53, 121, 248);
    text-decoration: underline;
    cursor: pointer;
  }
}

</style>
